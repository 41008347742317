@font-face {
  font-family: 'Alibaba PuHuiTi';
  src: local('Alibaba PuHuiTi'), url('./assets/fonts/Alibaba-PuHuiTi-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alibaba PuHuiTi Regular';
  src: local('Alibaba PuHuiTi'), url('./assets/fonts/Alibaba-PuHuiTi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alibaba PuHuiTi Light';
  src: local('Alibaba PuHuiTi'), url('./assets/fonts/Alibaba-PuHuiTi-Light.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'Cinecaption Regular';
  src: local('Cinecaption'), url('./assets/fonts/cinecaption\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AlimamaFangYuan';
  src: local('AlimamaFangYuan'), url('./assets/fonts/AlimamaFangYuanTiVF-Thin-2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Times New Roman';
  src: local('OPTITimes'), url('./assets/fonts/times\ new\ roman.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Times New Roman';
  src: local('OPTITimes'), url('./assets/fonts/times\ new\ roman\ italic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'wencangshufang';
  src: local('wencangshufang'), url('./assets/fonts/wencangshufang.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
